'use client';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { banner1 } from '../helper/Utils';

const HomeSlider = () => {
    const imageList = [
        {
            img: banner1,
            alt: 'banner1',
        },
        {
            img: banner1,
            alt: 'banner1',
        },
        {
            img: banner1,
            alt: 'banner1',
        },
    ]
    return (
        <div className='relative w-full h-[600px]'>
            <Slider
                dots={false}
                autoplay={true}
                autoplaySpeed={3000}
                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
            >
                {
                    imageList.map((data, i) => {
                        return (
                            <div key={i}>
                                <img alt={data.alt} className='object-cover h-[600px] w-full' src={data.img} width={1500} height={500} />
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default HomeSlider