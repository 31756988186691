import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className="wrapper max-w-[1200px] px-3 py-10 m-auto">
            <header>
                <h1>Privacy Policy</h1>
            </header>

            <section>
                <h2>1. Introduction</h2>
                <p>
                    Welcome to the Privacy Policy of Akshar Polytex India LLP ("we," "us,"
                    or "our"). This Privacy Policy explains how we collect, use, disclose,
                    and safeguard your personal information when you visit our website, use
                    our services, or interact with us in any way. By accessing or using our
                    services, you consent to the practices described in this Privacy Policy.
                </p>
            </section>

            <section>
                <h2>2. Information We Collect</h2>
                <p>
                    We may collect various types of information, including but not limited
                    to:
                </p>
                <ul>
                    <li>
                        <strong>Personal Information:</strong> This may include your name,
                        email address, postal address, phone number, and other identifying
                        information you provide to us.
                    </li>
                    <li>
                        <strong>Usage Information:</strong> We may collect information about
                        how you interact with our website, including IP addresses, browser
                        type, referring/exit pages, operating system, and date/time stamps.
                    </li>
                    <li>
                        <strong>Cookies and Similar Technologies:</strong> We may use cookies,
                        web beacons, and similar tracking technologies to collect information
                        about your browsing behavior and preferences.
                    </li>
                </ul>
            </section>

            <section>
                <h2>3. How We Use Your Information</h2>
                <p>
                    We may use the information we collect for various purposes, including
                    but not limited to:
                </p>
                <ul>
                    <li>Providing and maintaining our services.</li>
                    <li>
                        Communicating with you, responding to inquiries, and sending you
                        updates or promotional materials.
                    </li>
                    <li>Customizing and improving our website and services.</li>
                    <li>Analyzing usage patterns, trends, and demographics.</li>
                    <li>
                        Complying with legal obligations and protecting our rights and
                        interests.
                    </li>
                </ul>
            </section>

            <section>
                <h2>4. Information Sharing</h2>
                <p>
                    We may share your information with third parties under certain
                    circumstances, including:
                </p>
                <ul>
                    <li>With your consent.</li>
                    <li>When required by law or legal process.</li>
                    <li>
                        To protect our rights, privacy, safety, or property, and that of our
                        users and the public.
                    </li>
                    <li>
                        In connection with a merger, acquisition, or sale of all or a portion
                        of our assets.
                    </li>
                </ul>
            </section>

            <section>
                <h2>5. Security</h2>
                <p>
                    We take reasonable measures to protect your personal information from
                    unauthorized access, disclosure, alteration, or destruction. However,
                    please be aware that no method of data transmission over the internet or
                    electronic storage is entirely secure, and we cannot guarantee absolute
                    security.
                </p>
            </section>

            <section>
                <h2>6. Changes to this Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy to reflect changes to our practices or
                    for other operational, legal, or regulatory reasons. We will notify you
                    of any material changes by posting the updated Privacy Policy on our
                    website and updating the "Last Updated" date at the top of this page.
                </p>
            </section>

            <section>
                <h2>7. Contact Us</h2>
                <p>
                    If you have any questions, concerns, or requests regarding this Privacy
                    Policy, please contact us at
                    <a href="mailto:sales@aksharpolytexindia.com"
                    >sales@aksharpolytexindia.com.</a>
                </p>
            </section>

            <footer>
                <p>Last Updated: 15/09/2023</p>
            </footer>
        </div>
    )
}

export default PrivacyPolicy