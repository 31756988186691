import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import { Product, Product1, Product2, Product3, Product4, Product5, Product6, Product7, Product8, Product9, Product10, Product11 } from "../helper/Utils";

const ImageGallary = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
        Product, Product1, Product2, Product3, Product4, Product5, Product6, Product7, Product8, Product9, Product10, Product11,
    ];

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    return (
        <div>
            <div className='grid md:grid-cols-3 grid-cols-2 md:gap-4 gap-2 m-auto'>
                {images.map((data, i) => (
                    <div className='col-span-1'>
                        <img
                            src={data}
                            onClick={() => openImageViewer(i)}
                            key={i}
                            alt=""
                            className={'object-cover w_web border-2 border-black cursor-pointer md:h-[300px] sm:[230px] h-[150px]'}
                            loading='lazy'
                        />
                    </div>
                ))}

                {isViewerOpen && (
                    <ImageViewer
                        src={images.map((data) => (data))}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        disableScroll={false}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)"
                        }}
                        closeOnClickOutside={true}
                    />
                )}
            </div>
        </div>
    )
}

export default ImageGallary