import './App.css';
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Home from './components/Home';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import TermsCondition from './components/termsconditions/TermsCondition';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/terms-condition" element={<TermsCondition />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
