"use client"
import React, { useEffect, useState } from 'react'
import { IoMenu, RiArrowDropDownLine, IoClose } from '../../helper/Icon'
import { Logo } from '../../helper/Utils';

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    if (click == true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [click]);

  return (
    <div className='bg-white'>
      <div className='z-20 flex items-center justify-between m-auto gap-5 xl:max-w-[1140px] max-w-[95%] sm:p-5 p-3'>
        <a href='/' className='cursor-pointer'>
          <img
            src={Logo}
            alt="Main Logo"
            width={200}
            height={120}
          />
        </a>
        <div className={`${click ? "nav-options active" : "nav-options"} lg:flex hidden nav-options xl:text-[18px] text-base font-bold gap-5`}>
          <div onClick={() => setClick(false)}><a href='/' className='relative flex'>About us</a></div>
          <div onClick={() => setClick(false)}><a href='/' className='relative flex'>Specifications</a></div>
          <div onClick={() => setClick(false)}><a href='/' className='relative flex'>Capabilities</a></div>
          <div onClick={() => setClick(false)}><a href='/' className='relative flex'>Testimonials</a></div>
          <div onClick={() => setClick(false)}><a href='/' className='relative flex'>Contact us</a></div>
          <div onClick={() => setClick(false)}><a href='/' className='relative flex'>Blog</a></div>
        </div>
        <div className="z-50 lg:hidden block" onClick={handleClick}>
          {click ? (
            <IoClose className='text-2xl' onClick={handleClick} />
          ) : (
            <IoMenu className='text-2xl' onClick={handleClick} />
          )}
        </div>
      </div>
    </div>
  )
}

export default Header