import React from 'react'

const TermsCondition = () => {
    return (
        <div className="wrapper max-w-[1200px] px-3 py-10 m-auto">
            <h1>Terms and Conditions</h1>

            <h2>1. Introduction</h2>
            <p>Welcome to Akshar Polytex India LLP. These terms and conditions govern your use of our website and services. By using our website, you agree to comply with and be bound by these terms.</p>

            <h2>2. Definitions</h2>
            <p>Throughout these terms and conditions, the following terms have specific meanings:</p>
            <ul>
                <li><strong>Company:</strong> Refers to Akshar Polytex India LLP.</li>
                <li><strong>Website:</strong> Refers to our website located at www.example.com.</li>
                <li><strong>User:</strong> Refers to anyone who accesses or uses our website.</li>
            </ul>

            <h2>3. Intellectual Property</h2>
            <p>All content on this website is the intellectual property of Akshar Polytex India LLP and is protected by copyright and other applicable laws. You may not use, reproduce, or distribute our content without our explicit permission.</p>

            <h2>4. User Responsibilities</h2>
            <p>When using our website, you agree to:</p>
            <ul>
                <li>Comply with all applicable laws and regulations.</li>
                <li>Not engage in any unauthorized or unlawful activities on our website.</li>
                <li>Not interfere with the functionality of the website.</li>
            </ul>

            <h2>5. Privacy Policy</h2>
            <p>Your use of this website is also governed by our Privacy Policy, which can be found <a href="privacy-policy.html">Privacy Policy</a></p>

            <h2>6. Disclaimers</h2>
            <p>We provide our website and services "as is" and make no warranties or representations regarding the accuracy, completeness, or reliability of the content. We are not liable for any damages or losses resulting from the use of our website.</p>

            <h2>7. Limitation of Liability</h2>
            <p>We are not liable for any indirect, incidental, or consequential damages arising from your use of our website or services.</p>

            {/* <h2>8. Governing Law</h2>
<p>These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction].</p> */}

            <h2>9. Contact Information</h2>
            <p>If you have any questions or concerns about these terms and conditions, you may contact us at <a href="tel: +91 96875 58000">
                +91 96875 58000</a>.</p>

            <p>Last updated: 15/09/2023</p>
        </div>
    )
}

export default TermsCondition