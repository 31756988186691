import React, { useState } from 'react'
import { FaDollarSign, FaEye, FaRocket, FaThumbsUp } from 'react-icons/fa'
import HomeSlider from './HomeSlider'
import { about } from '../helper/Utils'
import axios from 'axios'
import ImageGallary from './ImageGallary'
const apiUrl = 'https://contactapi.mobiginie.com/api/v1/addContactus';
const Home = () => {

    const initialValues = {
        fname: "",
        lname: "",
        email: "",
        phoneNo: "",
        message: "",
        domain: "https://aksharpolytexindia.com/"
    }
    const [contactForm, setContactForm] = useState(initialValues)
    const [sendMail, setSendMail] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setContactForm({ ...contactForm, [name]: value });
    }

    const customHeaders = {
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, DELETE, PATCH, POST, PUT',
        'Access-Control-Allow-Headers': 'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version'
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const payload = {
            ...contactForm,
            name: `${contactForm.fname} ${contactForm.lname}`
        }

        axios.post(apiUrl, payload, {
            headers: customHeaders,
        })
            .then(response => {
                // Handle success
                setContactForm(initialValues);
                setSendMail(true)
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
            });
    }


    return (
        <div className="content-wrapper">
            <HomeSlider />
            <section className="wrapper bg-light">
                <div className="container py-14 py-md-16">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-17 align-items-center">
                        <div className="col-lg-6 position-relative order-lg-2">
                            <div className="shape bg-dot primary rellax w-16 h-20" data-rellax-speed="1"></div>
                            <div className="overlap-grid overlap-grid-2">
                                <img src={about} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="display-4 mb-3">About us</h2>
                            <p className="lead fs-lg">AKSHAR POLYTEX LLP is fast becoming the first choice for world-class Manufacturer Cum Exporter of a Wide Range of FIBC Bags such as Standard FIBC Bags, Circular Bags, 4 Panel Bags, U Panel Bags, Baffle Bags, Ventilated Bags, One-Two Loop Bags, Food & Pharma Grade FIBC Bags, Laminated & Un-laminated FIBC Bags & Highly Customizable FIBC Bags to accommodate your specific handling, Transportation & Storage.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-soft-primary">
                <div className="container py-14 py-md-16 pb-md-17">
                    <div className="row">
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg card-border-bottom border-soft-yellow">
                                <div className="card-body">
                                    <div className='mb-4'><FaEye className='text-4xl link-yellow' /></div>
                                    <h4>VISION</h4>
                                    <p className="mb-2">We Would Stand For Safety, Integrity, Diversity, Powered by Excellence and Serve Our Clients with Honor, Consideration and due Respect.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg card-border-bottom border-soft-green">
                                <div className="card-body">
                                    <div className='mb-4'><FaRocket className='text-4xl link-green' /></div>
                                    <h4>MISSION</h4>
                                    <p className="mb-2">To Become the Most Reliable FIBC Manufacturer.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg card-border-bottom border-soft-orange">
                                <div className="card-body">
                                    <div className='mb-4'><FaThumbsUp className='text-4xl link-orange' /></div>
                                    <h4>QUALITY</h4>
                                    <p className="mb-2">Our Quality Commitment to Cleanliness ensures Industrial FIBC Bag free from Contamination, germs, dust & pollutants. Our Quality is Consistent and Strength as per requirement of Customers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg card-border-bottom border-soft-blue">
                                <div className="card-body">
                                    <div className='mb-4'><FaDollarSign className='text-4xl link-blue' /></div>
                                    <h4>OUR VALUE</h4>
                                    <p className="mb-2">Assured Quality, Committed Delivery, Reasonable Price with Long Terms & Strong Business Relationship</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* PRODUCT */}
            <section className="wrapper">
                <div className="container py-14 py-md-16 ">
                    <div className="row">
                        <div className="col-md-12 mx-auto text-center">
                            <h2 className="display-4 mb-3">PRODUCT SPECIFICATIONS</h2>
                            <p className="lead fs-lg mb-6 px-xl-10 px-xxl-15">product has diﬀerent varieties and attachment as bellows</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="elementor-text-editor elementor-clearfix"><p><strong>SWL Range: </strong>500 kg to 2000 kg.</p><p><strong>SF Option: </strong>5:1 / 6:1 / 7:1</p><p><strong>Lifting Option:</strong> One loop, Two loop, Four loop, Tunnel loop. Loops made from PP loop (standard loops), PP Multiﬁlament loops (for soft loops) PP ﬁbrillated loops (for semi soft loops) available in various designs &amp; colours.</p><p><strong>&nbsp;Filling Option: </strong>Filling spout, Skirt (duﬄe top), Flap, Iris (Pyjama/Bonout), Star closer (Pattle closer).</p><p>&nbsp;<strong>Discharge Option:</strong>&nbsp; Discharge spout, Skirt (duﬄe), Flap, Iris (Pyjama/Bonout), Star closer (Pattle closer) &amp; Conical.</p><p>&nbsp;<strong>Liner:</strong> With or without liner. Liner comes in Loose, Glued, Tabbed, Stitched Liners, Suspended liners and form ﬁt/ shape liners.</p><p>&nbsp;<strong>Printing Options:</strong> Printed (up to 4 colour) and unprinted.</p><p>&nbsp;<strong>Sewing Options:</strong> Hiracle &amp; safety, double chain with or without dustproof.</p><p>&nbsp;<strong>Sewing Thread/Webbing:</strong> In various colours including blue, black, green and red. All Colours deﬁned by Pantone system</p><p>&nbsp;<strong>Document Pockets:</strong> Ziplock – In various sizes and materials including transparent PE and transparent PP. Also available in self closing type.</p><p>&nbsp;<strong>Fabric:</strong> Flat woven or tubular woven fabric with Coated or uncoated.</p><p>With over 12 years of experience, we offer both “traditional” and some more special destinations. Whether you want a vacation at the sea or skiing, a circuit in the country or abroad, an escape from the daily or a team building, Neve Agency consultants are ready to offer you irreproachable services at affordable prices.</p></div>
                        </div>
                        <div className="col-md-12 mt-8">
                            <ImageGallary />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="wrapper">
                <div className="container py-14 py-md-16 ">
                    <div className="row">
                        <div className="col-md-12 mx-auto text-center">
                            <h2 className="display-4 mb-3">PRODUCT IMAGES</h2>
                        </div>
                    </div>
                    <div className="row">
                        
                    </div>
                </div>
            </section> */}
            {/* PRODUCT */}
            {/* CAPABILITIES */}
            <section className="wrapper bg-soft-primary">
                <div className="container py-14 py-md-16 ">
                    <div className="row">
                        <div className="col-md-12 mx-auto text-center">
                            <h2 className="display-4 mb-8">CAPABILITIES</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="elementor-widget-container">
                                <div className="elementor-text-editor elementor-clearfix"><p><strong>AKSHAR</strong> FIBC has the capabilities to produce 4 million industrial FIBC bags per annum as per customer requirements with international standard clean room facility, highly advanced machinery of Lohia &amp; other reputed Machinery and an advance equipped quality control laboratory managed by highly experience and expertise Officers.</p><p></p><p><strong>Extrusion:</strong> We produce 600 kgs per hour of PP tapes of various GSM and Strengths. We have the Beta gauge machines which continuously checks the size and the denier of the tape.</p><p><strong>Looms:</strong> High speed circular looms to produce quality fabric of any width and GSM.</p><p><strong>Belt Weaving:</strong> Needle loom Machine for Loop and Dust proof making.</p><p><strong>Lamination:</strong> Extrusion Coating Plant for fabrics lamination (width 2.2 meters).</p><p><strong>Belt Cutting:</strong> Auto Belt/Webbing Cutting, Folding and marking machines.</p><p><strong>Printing:</strong> Printing Machines (4 colour).</p><p><strong>Liner Plants:</strong> Multi-layer extrusion Plant for Liner.</p><p><strong>Gluing Machine:</strong> For glue ﬁtted Liner.</p><p><strong>Cleaning Machine:</strong> For remove if any contamination.</p><p><strong>Bales Pressing:</strong> We can make bales as per Customers Requirement Or as Maximum Loading inside Containers.</p><p><strong>Testing Lab:</strong> We have latest equipment of Tensile Testing, Elongation Testing, Load Test, RIG Test to ensure Tape, Belt, Fabrics and bag quality.</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CAPABILITIES */}
            {/* contact us  */}
            <section className="wrapper bg-light">
                <div className="container py-14 py-md-16">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <h2 className="display-4 mb-3 text-center">ANY INQUIRIES?</h2>
                            <p className="lead text-center mb-10">We are here for you, 24/7, to help you and reply within the hour.</p>
                            <form onSubmit={handleSubmit}>
                                <div className="messages">

                                </div>
                                <div className="row gx-4">
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input id="form_name" type='text' name='fname' value={contactForm.fname} onChange={handleChange} className="form-control" required />
                                            <label for="form_name">First Name *</label>
                                            <div className="valid-feedback"> Looks good! </div>
                                            <div className="invalid-feedback"> Please enter your first name. </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input id="form_lastname" type='text' name='lname' value={contactForm.lname} onChange={handleChange} className="form-control" required />
                                            <label for="form_lastname">Last Name *</label>
                                            <div className="valid-feedback"> Looks good! </div>
                                            <div className="invalid-feedback"> Please enter your last name. </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input id="form_email" type='email' name='email' value={contactForm.email} onChange={handleChange} className="form-control" required />
                                            <label for="form_email">Email *</label>
                                            <div className="valid-feedback"> Looks good! </div>
                                            <div className="invalid-feedback"> Please provide a valid email address. </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input id="form_phone" type='text' name='phoneNo' value={contactForm.phoneNo} onChange={handleChange}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} maxLength='10' className="form-control" required />
                                            <label for="form_phone">phone *</label>
                                            <div className="valid-feedback"> Looks good! </div>
                                            <div className="invalid-feedback"> Please provide a valid email address. </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating mb-4">
                                            <textarea rows='4' id="form_message" name='message' value={contactForm.message} onChange={handleChange} className="form-control" required></textarea>
                                            <label for="form_message">Message *</label>
                                            <div className="valid-feedback"> Looks good! </div>
                                            <div className="invalid-feedback"> Please enter your messsage. </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button className="btn btn-primary rounded-pill btn-send mb-3" type='submit' >Send message</button>
                                        <p className="text-muted"><strong>*</strong> These fields are required.</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            {
                                sendMail &&
                                <div className='mt-5 border-2 border-green-500 p-3'>
                                    Thank you! We have Received your message and someone from our team will contact you within 24 Hours. If you want to call us right away, Call us on <a href="tel: +91 96875 58000">+91 96875 58000</a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            {/* contact us  */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3681.3076317849564!2d70.80048977530365!3d22.679589179415725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDQwJzQ2LjUiTiA3MMKwNDgnMTEuMCJF!5e0!3m2!1sen!2sin!4v1693817173362!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}

export default Home