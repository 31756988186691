import React from 'react'
import { BsFacebook, BsLinkedin, BsTwitter, FaInstagram } from '../../helper/Icon'
import { Logo, LogoDark } from '../../helper/Utils'
import { Link, useNavigate } from 'react-router-dom'
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className='bg-secondary pt-12 pb-8'>
      <div className='text-white xl:max-w-[1140px] max-w-[95%] m-auto'>
        <div className='grid grid-cols-2 lg:grid-cols-5 gap-8'>
          <div className='font-medium col-span-2 flex gap-3 flex-col'>
            <div>
              <img
                src={Logo}
                alt="Logo"
                width={180}
                height={100}
              />
            </div>
            <div className='text-justify md:text-sm text-xs'>@ 2020, AKSHAR POLYTEX INDIA LLP | ALL RIGHTS RESERVED.</div>
            <div className='flex gap-3'>
              <a href="/"><BsFacebook /></a>
              <a href="/"><BsTwitter /></a>
              <a href="/"><BsLinkedin /></a>
              <a href="/"><FaInstagram /></a>
            </div>
          </div>
          <div className='col-span-3 grid md:grid-cols-4 grid-cols-1 gap-8'>
            <div className='font-bold col-span-1'>
              <div className='text-xl pb-4'>QUICK LINKS</div>
              <div className='md:text-sm text-xs flex flex-col gap-[15px]'>
                <div><a href='/' className='relative flex'>About us</a></div>
                <div><a href='/' className='relative flex'>Specifications</a></div>
                <div><a href='/' className='relative flex'>Capabilities</a></div>
                <div><a href='/' className='relative flex'>Testimonials</a></div>
                <div><a href='/' className='relative flex'>Contact us</a></div>
                <div><a href='/' className='relative flex'>Blog</a></div>
                <div><div onClick={() => navigate('/terms-condition')} className='relative flex cursor-pointer'>Terms & Conditions</div></div>
                <div><div onClick={() => navigate('/privacy-policy')} className='relative flex cursor-pointer'>Privacy Policy</div></div>
              </div>
            </div>
            <div className='font-medium lg:col-span-3 col-span-1'>
              <div className='text-xl pb-5 font-bold'>CONTACT US</div>
              <div className='md:text-sm text-xs flex flex-col gap-[10px]'>
                <div className='grid grid-cols-3 gap-1'>
                  <div className='font-black col-span-1'>Location:</div>
                  <div className='col-span-2'>lajai-wakaner Road,
                    B/H Hadmatiya Village,
                    At.Hadmatiya.
                    Ta.Tankara .Morbi.363641</div>
                </div>
                <div className='grid grid-cols-3 gap-1'>
                  <div className='font-black col-span-1'>Phone:</div>
                  <div className='col-span-2'>
                    <div><a href="tel: +91 96875 58000">+91 96875 58000</a></div>
                  </div>
                </div>
                <div className='grid grid-cols-3 gap-1'>
                  <div className='font-black whitespace-nowrap col-span-1'>Email:</div>
                  <div className='col-span-2'>
                    <div><a href="mailto:sales@aksharpolytexindia.com">sales@aksharpolytexindia.com</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='flex flex-col justify-center items-center pt-8 gap-3 font-bold'>
          <div className='text-xs font-medium'>Designed and Developed by <a href='https://nettyfy.com/' target='_blank' className='underline'> Nettyfy Technologies</a></div>
        </div>
      </div>
    </div>
  )
}

export default Footer